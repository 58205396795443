import React, {useState} from "react";
import s from './Epil.module.css'

import Set from "../Set/Set";
import setImage2 from "../../image/Set7.png";
import setImage3 from "../../image/Set3.png";
import setImage5 from "../../image/Set6.png";
import Modal from "../Modal/Modal";

const Epil = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const openModalWithDelay = () => {
        // Устанавливаем состояние модального окна в true через 500 миллисекунд (половина секунды)
        setTimeout(() => {
            setIsModalOpen(true);
        }, 300);
    };
    return <div className={s.content}>
        <div>
            <Modal isOpen={isModalOpen} onClose={closeModal}/>
        </div>

        <div className={s.wrapSet}>
        <div>
                <Set N={"Диодный"} p1={"Лазер"} setImage={setImage5} buttonText={"Подробнее"} targetComponent={"/laserepil"} customHeight={"350"}></Set>
            </div>
            <div>
                <Set N={"Александритовый"} p1={"Лазер"} setImage={setImage2} buttonText={"Подробнее"} targetComponent={"/alexandrit"} isModal={false} customHeight={"350"}></Set>
            </div>
        </div>
    </div>
}

export default Epil;