import React, {useState} from 'react';
import s from "./Alexandrit.module.css"
import Procedure from "../Procedure/Procedure";
import SliderDoPosle from "../SliderDoPosle/SliderDoPosle";

import imageDo4 from "../../image/doposle11.jpg"
import imagePosle4 from "../../image/doposle12.jpg"
import imageDo3 from "../../image/doposle21.jpg"
import imagePosle3 from "../../image/doposle22.jpg"
import imageDo2 from "../../image/doposle41.jpg"
import imagePosle2 from "../../image/doposle42.jpg"
import imageDo1 from "../../image/doposle61.jpg"
import imagePosle1 from "../../image/doposle62.jpg"
import TablePrice from "../TablePrice/TablePrice";
import Set from "../Set/Set";
import setImage1 from "../../image/Set1.png";
import setImage2 from "../../image/Set7.png";
import setImage3 from "../../image/Set3.png";
import setImage4 from "../../image/Set8.png";
import setImage5 from "../../image/Set6.png";
import Modal from "../Modal/Modal";
import Protivopokazaniya from "../Protivopokazaniya/Protivopokazaniya";
import Zagolovok from "../Zagolovok/Zagolovok";
import SliderAparat from "../SliderAparat/SliderAparat";

import imageaparat1 from "../../image/aparat23.png"
import imageaparat11 from "../../image/aparat22.png"
import imageaparat2 from "../../image/aparat21.png"
import imageaparat22 from "../../image/aparat24.png"


const opis = "-Данный лазер справится даже если волосок содержит крайне малое количество меланина (светло-серыми или пушковыми волосками)."
const plus1 =" Длительный эффект: Уже после первой сессии волосы замедляют рост и выпадают, а после полного курса кожа остается гладкой в течение нескольких лет"
const plus2="Безболезненность: Современные установки позволяют проводить процедуру безболезненно"
const plus3 = "Отсутствие неприятных последствий и побочных эффектов: Нет аллергических реакций, вросших волосков или темных пятен."

const dataWomen = [
    { id:1, time: '15 мин', name: 'Бикини классическое', price: '990₽' },
    { id:2, time:'15 мин', name: 'Бикини среднее', price: '1390₽' },
    { id:3, time:'20 мин', name: 'Бикини глубокое', price: '1890₽'},
    { id:4, time:'10 мин', name: 'Дорожка на животе', price: '390₽'},
    { id:5, time:'20 мин', name: 'Живот полностью', price: '1390₽'},
    { id:6, time:'15 мин', name: 'Подмышки', price: '990₽'},
    { id:7, time:'20 мин', name: 'Грудь/ареолы', price: '590₽'},
    { id:8, time:'30 мин', name: 'Спина', price: '2390₽'},
    { id:9, time:'20 мин', name: 'Поясница', price: '990₽'},
    { id:10, time: '20 мин', name: 'Ягодицы', price: '990₽'},
    { id:11, time: '30 мин', name: 'Голени', price: '2990₽'},
    { id:12, time: '30 мин', name: 'Бедра', price: '3590₽'},
    { id:13, time: '60 мин', name: 'Ноги полностью', price: '6890₽'},
    { id:14, time: '20 мин', name: 'Руки', price: '1890₽'},
    { id:15, time: '15 мин', name: 'Верхняя губа', price: '690₽'},
    { id:16, time: '15 мин', name: 'Подбородок', price: '690₽'},
    { id:17, time: '15 мин', name: 'Щеки/бакенбарды', price: '1690₽'},
    { id:18, time: '15 мин', name: 'Шея одна сторона', price: '890₽'},
    { id:19, time: '15 мин', name: 'Межбровье', price: '390₽'},
    { id:20, time: '30 мин', name: 'Лицо полностью', price: '2890₽'},
];

const dataSeti = [
    { id:1, time: '25 мин', name: 'Бикини классическое', price: '1890₽' },
    { id:3, time:'30 мин', name: 'Бикини глубокое', price: '3290₽'},
    { id:5, time:'30 мин', name: 'Живот полностью', price: '2890₽'},
    { id:6, time:'25 мин', name: 'Подмышки', price: '1290₽'},
    { id:7, time:'30 мин', name: 'Грудь', price: '2490₽'},
    { id:8, time:'40 мин', name: 'Спина', price: '5190₽'},
    { id:10, time: '30 мин', name: 'Ягодицы', price: '1890₽'},
    { id:11, time: '40 мин', name: 'Голени', price: '3990₽'},
    { id:12, time: '40 мин', name: 'Бедра', price: '6190₽'},
    { id:13, time: '70 мин', name: 'Ноги полностью', price: '8690₽'},
    { id:14, time: '30 мин', name: 'Руки', price: '2790₽'},
    { id:15, time: '25 мин', name: 'Верхняя губа', price: '990₽'},
    { id:16, time: '25 мин', name: 'Подбородок', price: '1690₽'},
    { id:17, time: '25 мин', name: 'Щеки/бакенбарды', price: '3390₽'},
    { id:18, time: '25 мин', name: 'Шея одна сторона', price: '1490₽'},
    { id:19, time: '25 мин', name: 'Межбровье', price: '890₽'},
    { id:20, time: '40 мин', name: 'Лицо полностью', price: '5490₽'},

];

const Alexandrit = () =>{
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const openModalWithDelay = () => {
        // Устанавливаем состояние модального окна в true через 500 миллисекунд (половина секунды)
        setTimeout(() => {
            setIsModalOpen(true);
        }, 300);
    };

    return <div className={s.wrap}>
        <div>
            <Modal isOpen={isModalOpen} onClose={closeModal}/>
        </div>
        <SliderAparat Aparat1={imageaparat1} Aparat11={imageaparat11} Aparat2={imageaparat2} Aparat22={imageaparat22} >
        </SliderAparat>
        <Procedure nameProcedure={"Александритовая эпиляция"} opisanie={opis} plus1 ={plus1} plus2={plus2} plus3={plus3}></Procedure>
        <SliderDoPosle imageDo1={imageDo1} imagePosle1={imagePosle1} imageDo2={imageDo2} imagePosle2={imagePosle2}
                       imageDo3={imageDo3} imagePosle3={imagePosle3} imageDo4={imageDo4} imagePosle4={imagePosle4}
                       countProcedur1={8} countProcedur2={4} countProcedur3={2} countProcedur4={1}
        ></SliderDoPosle>

        <Zagolovok showLine={false} textH1={"Противопоказания"}></Zagolovok>
        <Protivopokazaniya  protiv1={"онкология"} protiv2={"️беременность, лактация"} protiv3={"миома матки"} protiv4={"эпилепсия "}
                            protiv5={"️туберкулёз"} protiv6={"лишай, герпес в активной фазе"} protiv7={"️витилиго"} protiv8={"️псориаз"} protiv9={"загар в течение недели"}
                            protiv10={"️повышенная температура тела"} protiv11={"наличие кардиостимулятора"} >
        </Protivopokazaniya>
        <TablePrice data={dataWomen} caption={"ЖЕНСКИЙ ПРАЙС"}></TablePrice>
        <div className={s.wrapSet}>
            <div>
                <Set N={"Set 1 - 2390р"} p1={"Бикини любое"} p2={"Подмышки"} setImage={setImage1}
                     isModal={true} buttonText={"Получить скидку 30%"} openModal={openModalWithDelay} customHeight={"380"}></Set>
            </div>
            <div>
                <Set N={"Set 2 - 5190р"} p1={"Бикини любое"} p2={"Подмышки"} p3={"Голени"} setImage={setImage2}
                     isModal={true} buttonText={"Получить скидку 30%"} openModal={openModalWithDelay} customHeight={"400"}></Set>
            </div>
            <div>
                <Set N={"Set (2+)  6890р"} p1={"Бикини любое"} p2={"Подмышки"} p3={"Голени"} p4={"1/2 Бедра"} setImage={setImage3}
                     isModal={true} buttonText={"Получить скидку 30%"} openModal={openModalWithDelay} customHeight={"420"}></Set>
            </div>
            <div>
                <Set N={"Set 3 - 7890р"} p1={"Бикини любое"} p2={"Подмышки"} p3={"Ноги"} p4={"Живот"} p5={"Лицо"} setImage={setImage4}
                     isModal={true} buttonText={"Получить скидку 30%"} openModal={openModalWithDelay} customHeight={"440"}></Set>
            </div>
            <div>
                <Set N={"Set 4 - 9390р"} p1={"Бикини любое"} p2={"Подмышки"} p3={"Ноги"} p4={"Руки"} p5={"Лицо"} p6={"Живот"} setImage={setImage5}
                     isModal={true} buttonText={"Получить скидку 30%"} openModal={openModalWithDelay} customHeight={"460"}></Set>
            </div>
        </div>
        <TablePrice data={dataSeti} caption={"МУЖСКОЙ ПРАЙС"}></TablePrice>
    </div>

}

export default Alexandrit;