import React, {useState} from 'react';
import s from "./Lpg.module.css"
import Procedure from "../Procedure/Procedure";
import SliderDoPosle from "../SliderDoPosle/SliderDoPosle";
import imageDo4 from "../../image/lpg1.png"
import imagePosle4 from "../../image/lpg2.png"
import imageDo3 from "../../image/lpg3.png"
import imagePosle3 from "../../image/lpg4.png"
import imageDo2 from "../../image/lpg5.png"
import imagePosle2 from "../../image/lpg6.png"
import imageDo1 from "../../image/lpg7.png"
import imagePosle1 from "../../image/lpg8.png"
import TablePrice from "../TablePrice/TablePrice";
import Modal from "../Modal/Modal";
import Zagolovok from "../Zagolovok/Zagolovok";
import Protivopokazaniya from "../Protivopokazaniya/Protivopokazaniya";

import imageaparat1 from "../../image/aparat5.png"
import imageaparat11 from "../../image/aparat7.png"
import imageaparat2 from "../../image/aparat6.png"
import imageaparat22 from "../../image/aparat8.png"
import imageaparat3 from "../../image/aparat17.png"
import imageaparat33 from "../../image/aparat18.png"
import SliderAparat from "../SliderAparat/SliderAparat";

const opis = "(вакуумно-роликовый, или эндермологический) – это аппаратная методика, применяемая для коррекции лица и тела. Массаж выполняется специальным аппаратом. Насадку подбирают индивидуально, учитывая состояние кожи и проблемные зоны. Вакуум создает активный обмен веществ и усиливает лимфодренаж. Ролики разминают подкожно-жировую клетчатку, а вакуум усиливает приток крови. Это способствует выработке коллагена и эластина, улучшает упругость и эластичность кожи."
const plus1 ="Уменьшает выраженность целлюлита."
const plus2="Уменьшает спазм в мышцах."
const plus3 = "Улучшает тургор кожи."
const plus4 = "Уменьшает объемы тела."



const dataWomen = [
    { id:4, time:'30 мин', name: 'Пробный сеанс', price: '990₽'},
    { id:1, time: '30 мин', name: 'Все тело/Отдельная зона по желанию', price: '1400₽' },
    { id:1, time: '40 мин', name: 'Все тело/Отдельная зона по желанию', price: '1700₽' },
    { id:6, time:'30 мин', name: 'Абонемент 5 сеансов по 30min', price: '6450₽'},
    { id:7, time:'30 мин', name: 'Абонемент 10 сеансов по 30min', price: '12450₽'},
    { id:7, time:'30 мин', name: 'Абонемент 15 сеансов по 30min', price: '17850₽'},
    { id:8, time:'40 мин', name: 'Абонемент 5 сеансов по 40min', price: '7950₽'},
    { id:8, time:'40 мин', name: 'Абонемент 10 сеансов по 40min', price: '15450₽'},
    { id:7, time:'40 мин', name: 'Абонемент 15 сеансов по 40min', price: '21950₽'},
    { id:3, time:'', name: 'Покупка костюма', price: ' 990₽'},
    { id:5, time:'', name: 'Аренда костюма', price: ' 350₽'},

];

const Lpg = () =>{
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };
    const openModalWithDelay = () => {
        // Устанавливаем состояние модального окна в true через 500 миллисекунд (половина секунды)
        setTimeout(() => {
            setIsModalOpen(true);
        }, 300);
    };

    return <div className={s.wrap}>
        <div>
            <Modal isOpen={isModalOpen} onClose={closeModal}/>
        </div>
        <SliderAparat Aparat1={imageaparat1} Aparat11={imageaparat11} Aparat2={imageaparat2} Aparat22={imageaparat22} Aparat3={imageaparat3} Aparat33={imageaparat33}>
        </SliderAparat>
        <Procedure nameProcedure={"LPG-массаж"} opisanie={opis} plus1 ={plus1} plus2={plus2} plus3={plus3} plus4={plus4}></Procedure>
        <SliderDoPosle imageDo1={imageDo1} imagePosle1={imagePosle1} imageDo2={imageDo2} imagePosle2={imagePosle2}
                       imageDo3={imageDo3} imagePosle3={imagePosle3} imageDo4={imageDo4} imagePosle4={imagePosle4}

        ></SliderDoPosle>

        <Zagolovok showLine={false} textH1={"Противопоказания"}></Zagolovok>
        <Protivopokazaniya protiv1={"беременность"} protiv2={"️онкологические заболевания"} protiv3={"растущая миома матки"} protiv4={"пороки сердца"}
                           protiv5={"заболевания кросви, склонность к тромбообразованию, тромбозы в анамнезе"} protiv6={"недавно перенесенные хирургические операции, открытые раны"}
                           protiv7={"тяжелые формы варикозного расширения вен"} protiv8={"заболевания кожи: экзема, псориаз, лишай;"} protiv9={"туберкулез"} protiv10={"СПИД"}
                           protiv11={"эпилепсия"} protiv12={"простудные заболевания, повышенная температура тела"} protiv13={"алкогольное опьянение"} protiv14={"обильная менструация"}
                           protiv15={"кровотечения любого генеза"}
        ></Protivopokazaniya>
        <TablePrice data={dataWomen} caption={"ПРАЙС"}></TablePrice>

    </div>
}

export default Lpg;