import React from 'react';
import {YMaps, Map, Placemark, FullscreenControl, ZoomControl} from '@pbe/react-yandex-maps';
import s from './Contacts.module.css'
import Zagolovok from "../Zagolovok/Zagolovok";
import CustomLink from "../CustomLink/CustomLink";
import { SocialIcon } from 'react-social-icons';


const Contacts = () => {
    const defaultState = {
        center: [56.486607, 84.980086],
        zoom: 15,
        controls: [],
    };
    const sovetskaya = {
        center: [56.468851, 84.953820],
        zoom: 14,
        controls: [],
    };
    return (
        <div className={s.wrap}>
            <div className={s.contacts}>
                <div>
                    <Zagolovok textH1={"Наши контакты"}/>
                </div>
                <div>
                    <CustomLink to="tel:+79832300938">+7 983 230-09-38</CustomLink>
                </div>
                <div className={s.SocialIcons}>
                <SocialIcon bgColor="#5c5a5b" style={{ height: 35, width: 35, marginRight: 10 }}  url="https://vk.com/tomsk_epilation_toplaser" />
                <SocialIcon bgColor="#5c5a5b" style={{ height: 35, width: 35, marginRight: 10 }} url="https://www.instagram.com/toplaser_tomsk/" />
                <SocialIcon bgColor="#5c5a5b" style={{ height: 35, width: 35 }} url="https://telegram.me/toplaser_tomsk" />
                </div>

                {/* <div>
                    <p className={s.address}>г.Томск, Комсомольский пр-кт, 15а, оф.404</p>
                </div> */}
                {/* <div className={s.map}> */}
                    <YMaps>
                        {/* <Map defaultState={defaultState}>
                            <Placemark geometry={[56.486607, 84.980086]}/>
                            <FullscreenControl/>
                            <ZoomControl options={{float: "right"}}/>
                        </Map> */}
                        <p >г.Томск, ул.Советская, 60</p>
                        <Map defaultState={sovetskaya}>
                            <Placemark geometry={[56.468851, 84.953820]}/>
                            <FullscreenControl/>
                            <ZoomControl options={{float: "right"}}/>
                        </Map>
                    </YMaps>
                {/* </div> */}

            </div>

        </div>
    )
}

export default Contacts;


