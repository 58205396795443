import React, { useEffect, useRef, useState } from "react";
import CustomLink from "../CustomLink/CustomLink";
import {FaBars, FaTimes} from 'react-icons/fa'
import logo from '../../image/Logo.png'
import NavButtonZapis from "../Buttons/navButtonZapis/NavButtonZapis";
import s from './Navbar2.module.css'


const Navbar = ({openModal}) => {

    const navRef = useRef();
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false); // Состояние для открытия/закрытия подраздела
    const closeNavbar = () => {
        navRef.current.classList.remove(s.responsive_nav);
        setIsSubmenuOpen(false);  // Закрываем подменю при закрытии навбара
    };

    const showNavbar = () => {
        navRef.current.classList.toggle(s.responsive_nav);
        if (!navRef.current.classList.contains(s.responsive_nav)) {
            setIsSubmenuOpen(false);  // Закрываем подменю при закрытии навбара через кнопку
        }
    };
    const toggleSubmenu = () => {  // Функция для переключения состояния подраздела
        setIsSubmenuOpen(!isSubmenuOpen);
    };

    return <div className={s.wrap}  >
        <div >
            <CustomLink to="/" >
                <img src={logo} alt=""/>
            </CustomLink>
        </div>
        <div><NavButtonZapis openModal={openModal} buttonText="Получить скидку" isModal={true}/></div>
        <nav ref={navRef} className={s.nav}>
            <div className={s.blockLink}>
                <CustomLink to="/" onClick={closeNavbar}>Главная</CustomLink>
                                    {/* Основной пункт меню с подразделами */}
                                    <div className={s.menuItem}>
                        <span onClick={toggleSubmenu} className={s.dropdownLink}>Лазерная эпиляция</span> 
                        
                        {/* Выпадающее меню */}
                        {isSubmenuOpen && (  // Подменю, которое открывается при клике
                            <div className={s.submenu}>
                                <CustomLink to="/laserepil" onClick={closeNavbar}>Диодная эпиляция</CustomLink>
                                <CustomLink to="/alexandrit" onClick={closeNavbar}>Александритовая эпиляция</CustomLink>
                            </div>
                        )}
                    </div>

                {/* <CustomLink to="/laserepil" onClick={closeNavbar}>Лазерная эпиляция</CustomLink> */}

                <CustomLink to="/laserdelete" onClick={closeNavbar}>Лазерное удаление сосудов</CustomLink>

                <CustomLink to="/lpg" onClick={closeNavbar}>LPG Массаж</CustomLink>

                <CustomLink to="/contacts" onClick={closeNavbar}>Контакты</CustomLink>
            </div>

            <button className={`${s.navBtn} ${s.navCloseBtn}`} onClick={showNavbar}>
                <FaTimes/>
            </button>

        </nav>
        <button className={s.navBtn} onClick={showNavbar}>
            <FaBars/>
        </button>

    </div>
}

export default Navbar;