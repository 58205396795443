import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import s from './SliderAparat.module.css'
import { Autoplay, Pagination, Navigation,Grid,Controller } from 'swiper/modules';
import Doposle from "../DoPosle/Doposle";


const SliderAparat = ({Aparat1,Aparat11,Aparat2,Aparat22,Aparat3,Aparat33, caption1, caption2}) => {
    return (
        <div className={s.wrap}>
            <Swiper
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                }}
                breakpoints={{

                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                spaceBetween={30}
                loop={true}

                modules={[Autoplay, Pagination, Navigation,Grid,Controller]}
                pagination={true
                }
                style={{
                    "--swiper-pagination-color": "#ec70e8",
                    "--swiper-navigation-color": "#ec70e8",
                }}
                className={s.swiper}
            >
                <SwiperSlide className={s.swiperSlide}>
                    <Doposle image1={Aparat1} image2={Aparat11} ></Doposle>
                    <p className={s.caption}>{caption1}</p> {/* Подпись к первому слайду */}
                </SwiperSlide>
                <SwiperSlide className={s.swiperSlide}>
                    <Doposle image1={Aparat2} image2={Aparat22} ></Doposle>
                    <p className={s.caption}>{caption2}</p> {/* Подпись ко второму слайду */}
                </SwiperSlide>
                <SwiperSlide className={s.swiperSlide}>
                    <Doposle image1={Aparat3} image2={Aparat33} ></Doposle>
                    <p className={s.caption}>{caption2}</p> {/* Подпись ко второму слайду */}
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default SliderAparat;